<template>
   <v-dialog :value="!!clid" max-width="630" @click:outside="$emit('close')" >
      <v-card :loading="load_data">
         <v-card-title class="label-color">{{clid=='new'? 'Tambah':'Edit'}} Klien</v-card-title>
         <v-divider class="mx-4"></v-divider>
         <v-card-text>
            <v-form ref="form" class="row justify-space-between">
               <v-col cols="6">
                  <v-text-field label="ID" v-model="fm.id" :readonly="clid!=='new'" />
                  <v-text-field label="Nama" v-model="fm.label" :rules="[v => !!v || 'Nama harus diisi']" />
                  <v-text-field label="Code" v-model="fm.scc" />
                  <v-text-field label="Size Photo" v-model="fm.photo_size" />
                  <v-text-field label="Max Photo" v-model="fm.max_photo" />
               </v-col>
               <v-col cols="5">
                  <label>Logo</label>
                  <div>{{fm.logo || '---'}}</div>
                  <v-switch dense hide-details label="No Unifont" v-model="fm.no_unifont" :false-value="0" :true-value="1" />
                  <v-switch dense hide-details label="Single Form" v-model="fm.single_form" :false-value="0" :true-value="1" />
                  <v-switch dense hide-details label="Double Checker" v-model="fm.check2" :false-value="0" :true-value="1" />
                  <v-switch dense hide-details label="KPM" v-model="fm.kpm" :false-value="0" :true-value="1" />
                  <v-switch dense hide-details label="Disabled" v-model="fm.dis" :false-value="0" :true-value="1" />
                  <!-- <img-input label="Avatar" field="avatar" input-label="upload image" :url="fm.avatar" v-on:file="$set(fm,'avatar_img',arguments[0])" />
                  <img-input label="Sign" field="sign" input-label="upload image" :url="fm.sign" v-on:file="$set(fm,'sign_img',arguments[0])" /> -->
               </v-col>
               <v-col cols="12">
                  <v-text-field label="Tabel" v-model="fm.tabel" :disabled="$store.state.auth.user.id!==1" />
                  <v-textarea label="Custom Field" rows="3" outlined v-model="fm.cust" />
               </v-col>
            </v-form>
         </v-card-text>
         <v-divider else class="mx-4"></v-divider>
         <v-card-actions>
            <v-spacer />
            <v-btn color="#aaa" outlined rounded @click="$emit('close')">close</v-btn>
            <v-btn color="primary" :loading="on_submit" outlined rounded @click="save">save</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>
<script>
import { create, edit, detail, } from '../api/client';
export default {
   props: ['clid'],
   data: () => ({
      load_data: false,
      on_submit: false,
      fm: {}
   }),
   watch: {
      clid(val) {
         console.log('ch cid', val);
         if(!val) {
            this.$refs.form.reset();
         } else {
            this.load_data = true
            detail(val).then(rsl => {
               this.fm = rsl.data;
            }).finally(() => { this.load_data = false })
         }
      }
   },
   methods: {
      save() {
         if(!this.$refs.form.validate()) return false;
         const {id, ...data} = this.fm;
         this.on_submit = true;

         if(this.allowed_client.length > 0) {
            data.client = this.allowed_client.join(',');
         } else data.client = null;

         (this.uid == 'new' ? create(data) : edit(id, data)).then(rsl => {
            if(rsl.stat == 1) {
               this.$store.dispatch('alert', { title: 'Success', text: rsl.mess, type: 'success', showConfirmButton: false, timer: 3000})
               .then(() => {
                  this.$emit('close', true);
               });
            } else this.$store.dispatch('alert', {title: 'Ooops!', text: rsl.mess, type: 'warning'});
         }).finally(() => { this.on_submit = false });
      },
   }
}
</script>