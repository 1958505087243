<template>
   <v-container>
      <v-row class="top-nav">
         <v-col cols="5" class="title label-color">
            <v-app-bar-nav-icon v-if="$vuetify.breakpoint.mobile" @click.stop="$store.commit('toggleDrawer')"></v-app-bar-nav-icon>
            Client
         </v-col>
         <v-spacer />
         <v-col cols="5">
            <v-text-field dense label="Cari Klien" hide-details v-model="search"/>
         </v-col>
         <v-col cols="1">
            <v-btn icon color="#4b8" @click="show_filter=!show_filter" title="filter">
               <v-icon>mdi-filter</v-icon>
            </v-btn>
         </v-col>
      </v-row>
      <v-expand-transition>
         <v-row class="top-nav" v-show="show_filter" justify="end">
            <v-col cols="12" sm="4" lg="2">
               <v-select hide-details clearable label="KPM" v-model="filter.kpm" :items="kpm_opt" @change="getData"/>
            </v-col>
            <v-col cols="12" sm="4" lg="2">
               <v-select hide-details clearable label="Status" v-model="filter.dis" :items="status_opt" @change="getData"/>
            </v-col>
         </v-row>
      </v-expand-transition>
      <v-data-table
         :headers="header"
         :items="items"
         :server-items-length="total"
         item-key="id"
         :page.sync="page"
         :loading="loading"
         :footer-props="footer"
         :items-per-page="limit"
         :sort-by.sync="sort_by"
         :sort-desc.sync="sort_desc"
      >
         <template v-slot:[`item.act`]="{ item }">
            <v-btn outlined fab x-small color="primary" @click="cl_id=item.id" title="edit"><v-icon>mdi-pencil</v-icon></v-btn>
         </template>
         <template v-slot:[`item.dis`]="{ item }">
            {{item.dis === 1 ? 'Non Active': 'Active'}}
         </template>
         <template v-slot:[`item.kpm`]="{ item }">
            {{item.kpm === 1 ? 'YES': 'NO'}}
         </template>
      </v-data-table>
      <client-form :clid="cl_id" v-on:close="cl_id=null" />
   </v-container>
</template>
<script>
import {get} from '../api/client';
import ClientForm from '../components/ClientForm.vue';

export default {
   components: {ClientForm,},
   data: () => ({
      thimer: null,
      loading: false,
      show_filter: false,
      search: null,
      filter: {},
      items: [],
      footer: {
         'items-per-page-options': [15,30,50],
         showFirstLastPage: true,
         firstIcon: 'mdi-arrow-collapse-left',
         lastIcon: 'mdi-arrow-collapse-right',
         prevIcon: 'mdi-minus',
         nextIcon: 'mdi-plus'
      },
      page: 1,
      sort_by: 'id',
      sort_desc: true,
      limit: 15,
      total: 0,
      header: [
         { text: 'ID', value: 'id' },
         { text: 'Client Name', value: 'label' },
         { text: 'Code', value: 'scc' },
         { text: 'KPM', value: 'kpm' },
         // { text: 'Branch', value: 'branch' },
         { text: 'Status', value: 'dis' },
         { text: 'Action', value: 'act', sortable: false }
      ],
      cl_id: null,
      more: {},
      all_cabang: [],
      all_grp: [],
      all_client: [],
      allowed_client: [],
      on_submit: false,
      status_opt: [{text:'Aktif',value:'0'},{text:'Non Aktif',value:'1'}],
      kpm_opt: [{text:'Non KPM',value:'0'},{text:'KPM',value:'1'}]
   }),
   mounted() {
      this.getData();
      // get('roles').then(rsl => { this.all_grp = rsl.map(x => ({text: x.grp, value: x.id})) });
      // get('branch').then(rsl => { this.all_cabang = rsl.map(x => ({text: x.branch, value: x.id})) });
   },
   methods: {
      getData() {
         clearTimeout(this.timer);
         this.timer = setTimeout(() => {
            this.loading = true;
            const query = {start: ((this.page - 1) * this.limit), limit: this.limit};
            if(this.sort_by) query.sort = this.sort_by;
            if(this.sort_desc) query.desc = this.sort_desc;
            for(let i in this.filter) if(this.filter[i]) query[i]=this.filter[i];
            if(this.search) query.search = this.search;

            get(query).then(rsl => {
               this.items = rsl.data;
               this.total = rsl.total;
            }).finally(() => { this.loading = false });
         }, 800);
      },
   },
   watch: {
      page() {
         this.getData();
      },
      limit() {
         this.getData();
      },
      sort_by() {
         this.getData();
      },
      sort_desc() {
         this.getData();
      },
      search() {
         this.getData();
      }
   },
}
</script>
